import type { ReactNode } from "react";
type ShareLinkProps = {
  readonly children: ReactNode;
  readonly label: string;
  readonly title: string;
  readonly url: string;
};
export function ShareLink({
  children,
  label,
  url
}: ShareLinkProps) {
  return <a className="block text-primary transition-all duration-100 hover:text-primary-hover motion-reduce:transition-none" href={url} rel="noreferrer" target="_blank" title={label} data-sentry-component="ShareLink" data-sentry-source-file="ShareLink.component.tsx">
      {children}
    </a>;
}