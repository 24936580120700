import { clsx } from "clsx";
import { type SubmitButtonProps } from "@/components/Button/components/SubmitButton/SubmitButton.component";
import { CreateProfileSubmitButton } from "./CreateProfileSubmitButton.component";
export function FullWidthCreateProfileSubmitButton(props: SubmitButtonProps) {
  const {
    className,
    ...rest
  } = props;
  /* eslint-disable react/jsx-props-no-spreading -- to be able to pass the submit button to the form, we need to spread props */
  return <CreateProfileSubmitButton {...rest} className={clsx(className, "w-full")} data-sentry-element="CreateProfileSubmitButton" data-sentry-component="FullWidthCreateProfileSubmitButton" data-sentry-source-file="FullWidthCreateProfileSubmitButton.component.tsx" />;
  /* eslint-enable */
}