"use client";

import React, { Fragment } from "react";
import { EmailLink } from "@/components/EmailLink/EmailLink.component";
import { Link } from "@/components/Link/Link.component";
import { useSite } from "@/contexts/site/site.context";
import type { ArticleContentModel } from "../../models/article-content-model.types";
import { PartnerLogo } from "./components/PartnerLogo.component";
type BylineProps = {
  readonly className?: string;
  readonly model: ArticleContentModel.Byline;
};
export function Byline({
  model
}: BylineProps) {
  const {
    locale
  } = useSite();
  return <div className="text-sm font-bold uppercase text-primary" data-sentry-component="Byline" data-sentry-source-file="Byline.component.tsx">
      {model.partnerMedia !== null ? <div className="flex pb-0.75">
          <Link href={model.partnerMedia.partnerUrl} openInNewTab>
            <span className="sr-only">{model.partnerMedia.partnerName}</span>
            <PartnerLogo className="h-2 w-auto max-w-full" partnerName={model.partnerMedia.partnerName} />
          </Link>
        </div> : null}
      {model.$class === "custom" ? <span>{model.value}</span> : <span>
          {{
        da: "af",
        de: "von",
        en: "by",
        no: "av",
        sv: "av"
      }[locale]}{" "}
          {model.authors.map(({
        email,
        fullName
      }, index) => <Fragment key={email}>
              {index > 0 ? <span>, </span> : null}
              {email.length > 0 ? <EmailLink className="whitespace-nowrap hover:underline" emailAddress={email}>
                  {fullName}
                </EmailLink> : <span>{fullName}</span>}
            </Fragment>)}
        </span>}
    </div>;
}