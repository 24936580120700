"use client";

import { useRef, type ReactNode } from "react";
import { useSnowplowArticleScrollEvent } from "@/hooks/tracking/snowplow/use-snowplow-article-scroll-event.hook";
import { useElementViewedPercentage } from "@/hooks/use-element-viewed-percentage.hook";
type ScrollTrackerProps = {
  readonly className?: string;
  readonly children: ReactNode;
};
export function ScrollTracker({
  className,
  children
}: ScrollTrackerProps) {
  const ref = useRef<HTMLDivElement>(null);
  const {
    percentageViewed
  } = useElementViewedPercentage({
    ref,
    roundToNearest: 25
  });
  useSnowplowArticleScrollEvent({
    percentageViewed
  });
  return <div className={className} ref={ref} data-sentry-component="ScrollTracker" data-sentry-source-file="ScrollTracker.component.tsx">
      {children}
    </div>;
}