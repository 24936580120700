"use client";

import { Link } from "@/components/Link/Link.component";
import type { PaywallModel } from "@/components/Paywall/models/paywall-model.types";
import { useSite } from "@/contexts/site/site.context";
type IndividualSubscriptionProps = {
  readonly model: PaywallModel.IndividualSubscription;
};
export function IndividualSubscription({
  model: {
    pricePerQuarter,
    shopUrl
  }
}: IndividualSubscriptionProps) {
  const {
    locale,
    name: siteName
  } = useSite();
  return <div className="py-3" data-sentry-component="IndividualSubscription" data-sentry-source-file="IndividualSubscription.component.tsx">
      <h1 className="pb-2 font-heading text-2xl font-theme-bold lg:text-3xl">
        {{
        da: `Få ubegrænset adgang til ${siteName} for ${pricePerQuarter} per kvartal`,
        de: `Erhalten Sie unbegrenzten Zugang zu allen Inhalten von ${siteName} für ${pricePerQuarter} pro Quartal`,
        en: `Get unlimited access to ${siteName} for ${pricePerQuarter} per quarter`,
        no: `Få ubegrenset tilgang til ${siteName} for ${pricePerQuarter} per kvartal`,
        sv: `Få obegränsad tillgång till ${siteName} för ${pricePerQuarter} per kvartal`
      }[locale]}
      </h1>
      <Link className="text-primary hover:underline" href={shopUrl} data-sentry-element="Link" data-sentry-source-file="IndividualSubscription.component.tsx">
        {{
        da: "Start dit abonnement her",
        de: "Starten Sie Ihr Abonnement hier",
        en: "Start your subscription here",
        no: "Start abonnementet ditt her",
        sv: "Starta din prenumeration här"
      }[locale]}
      </Link>
    </div>;
}