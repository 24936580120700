"use client";

import { trackStructEvent } from "@snowplow/browser-tracker";
import { usePathname, useSearchParams } from "next/navigation";
import { useEffect, useState } from "react";
import { useSnowplowContext } from "@/contexts/snowplow/snowplow.context";
import { useTrackingData } from "@/contexts/tracking/tracking.context";
import { useUser } from "@/contexts/user/user.context";
import { useCookiebotConsent } from "@/hooks/cookiebot/use-cookiebot-consent.hook";
import { useJpmuid } from "../jpmuid/use-jpmuid.hook";
import {
  buildUserPayload,
  buildPageViewPayload,
} from "./snowplow-payload/snowplow-common-payload";

type SnowplowArticleScrollEventProps = {
  readonly percentageViewed: number;
};

export function useSnowplowArticleScrollEvent({
  percentageViewed,
}: SnowplowArticleScrollEventProps) {
  const pathname = usePathname();
  const searchParams = useSearchParams();
  const { trackingData } = useTrackingData();
  const consent = useCookiebotConsent();
  const { jpmuid } = useJpmuid();
  const { model: user } = useUser();

  const [trackedPercentage, setTrackedPercentage] = useState<number>(0);

  const oneHundred = 100;
  // eslint-disable-next-line @typescript-eslint/no-magic-numbers -- Percentages are fixed
  const percentageMarkers = [25, 50, 75, 100];

  useSnowplowContext(); // Make sure snowplow is initialized

  useEffect(() => {
    // On any new page we track the element scroll again
    setTrackedPercentage(0);
  }, [pathname, searchParams]);

  useEffect(() => {
    if (!consent.submitted || trackingData === null) return;
    const userContext = buildUserPayload(user, jpmuid);
    const pageViewContext = buildPageViewPayload(trackingData);
    for (const percentageMarker of percentageMarkers) {
      if (
        percentageViewed >= percentageMarker &&
        trackedPercentage < percentageMarker
      ) {
        setTrackedPercentage(percentageViewed);
        trackStructEvent({
          action: "article_scroll_reach",
          category: "user_activity",
          context:
            pageViewContext !== null
              ? [userContext, pageViewContext]
              : [userContext],
          value: percentageMarker / oneHundred,
        });
      }
    }
  }, [percentageViewed, consent, jpmuid, trackingData]);
}
