"use client";

import { useSite } from "@/contexts/site/site.context";
import type { ArticleContentModel } from "../../models/article-content-model.types";
import { RelatedArticleTeaser } from "../RelatedArticleTeaser/RelatedArticleTeaser.component";
type RelatedArticleVerticalListProps = {
  readonly className?: string;
  readonly relatedArticles: ReadonlyArray<ArticleContentModel.RelatedArticle>;
};
export function RelatedArticleVerticalList({
  className,
  relatedArticles
}: RelatedArticleVerticalListProps) {
  const {
    locale
  } = useSite();
  return <div className={className} data-sentry-component="RelatedArticleVerticalList" data-sentry-source-file="RelatedArticleVerticalList.component.tsx">
      <h2 className="pb-2 text-sm font-theme-bold uppercase tracking-tight text-dim">
        {{
        da: "Relaterede artikler",
        de: "Verwandte Artikel",
        en: "Related articles",
        no: "Relaterte artikler",
        sv: "Relaterade artiklar"
      }[locale]}
      </h2>
      <ul className="space-y-2 divide-y divide-smoke">
        {relatedArticles.map(relatedArticle => <li className="pt-2" key={relatedArticle.targetUrl}>
            <RelatedArticleTeaser model={relatedArticle} />
          </li>)}
      </ul>
    </div>;
}