"use client";

import { clsx } from "clsx";
import { useEffect, useState } from "react";
import { useChronology } from "@/contexts/chronology/chronology.context";
import { useSite } from "@/contexts/site/site.context";
import type { ZonedDateTime } from "@/ts/date-time/date-time.types";
type LongPublicationDateTimeProps = {
  readonly className?: string;
  readonly zonedDateTime: ZonedDateTime;
};
export function LongPublicationDateTime({
  className,
  zonedDateTime
}: LongPublicationDateTimeProps) {
  const {
    locale
  } = useSite();
  const {
    toLongDateString,
    toShortTimeString
  } = useChronology();
  const [formattedDate, setFormattedDate] = useState<string | null>(null);
  const [formattedTime, setFormattedTime] = useState<string | null>(null);
  useEffect(() => {
    // Please note that because we are using `new Date()` here, we have
    // to wrap it in a `useEffect` hook to avoid hydration issues.
    // We can safely use `new Date()` in the browser because time
    // zone information is specified in the `zonedDateTime` prop.
    const dateToFormat = new Date(zonedDateTime);
    setFormattedDate(toLongDateString(dateToFormat));
    setFormattedTime(toShortTimeString(dateToFormat));
  }, [toLongDateString, toShortTimeString, zonedDateTime]);
  return <time className={clsx(className, "space-x-[1ch]")} dateTime={zonedDateTime} data-sentry-component="LongPublicationDateTime" data-sentry-source-file="LongPublicationDateTime.component.tsx">
      {formattedDate !== null ? <span>{formattedDate}</span> : null}

      {formattedTime !== null ? <span>
          {{
        da: `kl. ${formattedTime}`,
        de: `${formattedTime} Uhr`,
        en: `at ${formattedTime}`,
        no: `kl. ${formattedTime}`,
        sv: `kl. ${formattedTime}`
      }[locale]}
        </span> : null}
    </time>;
}