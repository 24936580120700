import { clsx } from "clsx";
import type { ReactNode } from "react";
import { OutlineCheckIcon } from "../Icons/components/HeroIcons/OutlineCheckIcon.component";
type ListItemProps = {
  readonly children: ReactNode;
  readonly className?: string;
  readonly position?: string;
  readonly type?: "bullet" | "checkmark";
  readonly tag?: keyof JSX.IntrinsicElements;
};
export function ListItem({
  children,
  className,
  position,
  type,
  tag = "li"
}: ListItemProps) {
  const TagComponent = tag;
  return <TagComponent className={clsx(className, "relative",
  // https://css-tricks.com/everything-you-need-to-know-about-the-gap-after-the-list-marker/
  type === "bullet" && "ml-[1ch] list-item list-['•'] pl-[0.75ch] marker:font-['Tahoma'] marker:text-[1em] marker:font-black marker:leading-none marker:tracking-normal marker:text-primary", type === "checkmark" && "flex items-start gap-x-1")} data-k5a-pos={position} data-sentry-element="TagComponent" data-sentry-component="ListItem" data-sentry-source-file="ListItem.component.tsx">
      {type === "checkmark" ? <span className="mt-0.75 rounded-full bg-primary">
          <OutlineCheckIcon className="h-1.5 w-1.5 text-white" />
        </span> : null}
      {children}
    </TagComponent>;
}