"use client";

import { Link } from "@/components/Link/Link.component";
import type { PaywallModel } from "@/components/Paywall/models/paywall-model.types";
import { useSite } from "@/contexts/site/site.context";
type CorporateSubscriptionProps = {
  readonly model: PaywallModel.CorporateSubscription;
};
export function CorporateSubscription({
  model: {
    externalUrl
  }
}: CorporateSubscriptionProps) {
  const {
    locale
  } = useSite();
  return <div className="py-3" data-sentry-component="CorporateSubscription" data-sentry-source-file="CorporateSubscription.component.tsx">
      <h1 className="pb-2 font-heading text-2xl font-theme-bold lg:text-3xl">
        {{
        da: "Få fuld adgang til dig og dine kollegaer",
        de: "Erhalten Sie unbegrenzten Zugang für sich und Ihr Team",
        en: "Get full access for you and your coworkers",
        no: "Få ubegrenset tilgang for deg og dine kolleger",
        sv: "Få obegränsad tillgång för dig och dina kollegor"
      }[locale]}
      </h1>
      <Link className="text-primary hover:underline" href={externalUrl} data-sentry-element="Link" data-sentry-source-file="CorporateSubscription.component.tsx">
        {{
        da: "Start et gratis virksomhedsprøveabonnement",
        de: "Starten Sie heute Ihr kostenloses Unternehmens-Probe-Abo",
        en: "Start a free company trial today",
        no: "Start en gratis prøveperiode for bedriften i dag",
        sv: "Starta en gratis företagsprovperiod idag"
      }[locale]}
      </Link>
    </div>;
}