import { FinansPartnerLogo } from "./FinansPartnerLogo.component";
import { JyllandsPostenPartnerLogo } from "./JyllandsPostenPartnerLogo.component";
import type { PartnerLogoProps } from "./partner-logo-props";
import { PolitikenPartnerLogo } from "./PolitikenPartnerLogo.component";

const partnerLogoByPartnerName: Readonly<
  Record<string, (props: PartnerLogoProps) => JSX.Element>
> = {
  Finans: FinansPartnerLogo,
  "Jyllands-Posten": JyllandsPostenPartnerLogo,
  Politiken: PolitikenPartnerLogo,
};
export function PartnerLogo(props: PartnerLogoProps) {
  return partnerLogoByPartnerName[props.partnerName](props);
}
