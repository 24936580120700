"use client";

import { useId } from "react";
type MsTeamsIconProps = {
  readonly className?: string;
};
export function MsTeamsIcon({
  className
}: MsTeamsIconProps) {
  const id = useId();
  return <svg className={className} fill="currentColor" viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg" data-sentry-element="svg" data-sentry-component="MsTeamsIcon" data-sentry-source-file="MsTeamsIcon.component.tsx">
      <defs data-sentry-element="defs" data-sentry-source-file="MsTeamsIcon.component.tsx">
        <clipPath id={`clip-path${id}`} data-sentry-element="clipPath" data-sentry-source-file="MsTeamsIcon.component.tsx">
          <rect data-name="Rectangle 720" fill="none" height="32" width="32" data-sentry-element="rect" data-sentry-source-file="MsTeamsIcon.component.tsx" />
        </clipPath>
      </defs>
      <path d="M11.414,19.533h1.537V14.045l1.917-.066v-1.36L9.7,12.785v1.389l1.674-.04Z" data-name="Path 3781" fill="currentFill" data-sentry-element="path" data-sentry-source-file="MsTeamsIcon.component.tsx" />
      <g data-name="Group 1580" data-sentry-element="g" data-sentry-source-file="MsTeamsIcon.component.tsx">
        <g clipPath={`url(#clip-path${id})`} data-name="Group 1579" data-sentry-element="g" data-sentry-source-file="MsTeamsIcon.component.tsx">
          <path d="M16,0A16,16,0,1,0,32,16,16,16,0,0,0,16,0m7.071,11.917a1.478,1.478,0,1,1-1.477,1.477,1.477,1.477,0,0,1,1.477-1.477m-4.751-.035a1.477,1.477,0,1,1,0,1.312Zm-.566,12.74-10.306-2.4V9.691L17.754,7.378Zm1.679-3.483H18.32V14.8h2.8V19.44a1.533,1.533,0,0,1-1.685,1.7m3.431-1.187h-1.27v-4.3h2.954v2.6a1.532,1.532,0,0,1-1.684,1.7" data-name="Path 3782" fill="currentFill" data-sentry-element="path" data-sentry-source-file="MsTeamsIcon.component.tsx" />
        </g>
      </g>
    </svg>;
}