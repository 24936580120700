import type { PartnerLogoProps } from "./partner-logo-props";
export function FinansPartnerLogo({
  className
}: PartnerLogoProps) {
  return <svg aria-hidden="true" className={className} viewBox="0 0 988.6 198.2" data-sentry-element="svg" data-sentry-component="FinansPartnerLogo" data-sentry-source-file="FinansPartnerLogo.component.tsx">
      <path d="M252.4,170.2l9.1,1.6v23.1h-94v-23.1l9.1-1.6c9.4-1.6,14-5.4,14-16.1V44c0-10.7-4.6-14.5-14-16.1l-9.1-1.6V3.2h94v23.1
		l-9.1,1.6c-9.4,1.6-14,5.4-14,16.1v110.1C238.4,164.9,243,168.6,252.4,170.2" data-sentry-element="path" data-sentry-source-file="FinansPartnerLogo.component.tsx" />
      <path d="M341.8,194.9h-72.2v-23.1l7.8-1.6c8.9-1.9,12.4-5.1,12.4-16.1V44c0-11.5-2.7-13.7-13.2-16.1l-7-1.6V3.2h77.1l64.7,121.4
		h1.3V44c0-11-3.5-14.5-12.4-16.1l-8.6-1.6V3.2H464v23.1l-7.8,1.6c-8.9,1.9-12.4,5.1-12.4,16.1v150.9h-46.2L322.2,55h-1.3v99.1
		c0,11,3.5,14.5,12.3,16.1l8.6,1.6V194.9" data-sentry-element="path" data-sentry-source-file="FinansPartnerLogo.component.tsx" />
      <path d="M526.3,194.9h-70.7v-23.1l6.7-1.6c9.4-2.1,13.2-7.3,16.4-16.1L531.4,3.2h44l50.2,148.2c4,11.5,8.1,16.4,17.7,18.8l6.2,1.6
		v23.1h-89.7v-23.1l7.8-1.6c9.7-1.9,10.2-8.6,6.7-20.1l-2.4-8h-58l-2.7,8c-3.5,11-1.9,17.7,8.1,20.1l7,1.6V194.9 M522,116.3h42.2
		l-19.9-65.8h-1.3L522,116.3z" data-sentry-element="path" data-sentry-source-file="FinansPartnerLogo.component.tsx" />
      <path d="M725.1,194.9h-72.2v-23.1l7.8-1.6c8.9-1.9,12.3-5.1,12.3-16.1V44c0-11.5-2.7-13.7-13.2-16.1l-7-1.6V3.2h77.1l64.7,121.4
		h1.3V44c0-11-3.5-14.5-12.4-16.1l-8.6-1.6V3.2h72.2v23.1l-7.8,1.6c-8.9,1.9-12.4,5.1-12.4,16.1v150.9H781L705.5,55h-1.3v99.1
		c0,11,3.5,14.5,12.4,16.1l8.6,1.6V194.9" data-sentry-element="path" data-sentry-source-file="FinansPartnerLogo.component.tsx" />
      <path d="M857,189l-2.4-47.8h26.8c0.8,4.6,3,13.7,5.4,18.3c5.4,10.2,16.4,11.3,28.2,11.3c19.1,0,27.7-8.9,27.7-22.3
		c0-16.9-10.7-21.5-29.5-28.7l-14.5-5.9c-26.3-9.4-42.4-25.8-42.4-56.7C856.2,25.5,876.6,0,925,0c24.7,0,41.6,3.8,52.1,7.3l2.7,45.1
		h-26.6c-1.3-4.8-3.8-12.6-9.7-18.8c-3.8-3.8-9.1-5.6-20.4-5.6c-15,0-24.7,5.9-24.7,17.2c0,12.6,7.5,19.6,25.2,26.3l14.8,5.6
		c30.3,11.3,50.2,26.8,50.2,61c0,35.4-25,60.2-73.6,60.2C893.3,198.2,871,194.4,857,189" data-sentry-element="path" data-sentry-source-file="FinansPartnerLogo.component.tsx" />
      <path d="M0,194.9v-23.1l9.1-1.6c9.4-1.6,14-5.4,14-16.1V44c0-10.7-4.6-14.5-14-16.1L0,26.3V3.2h157.6l2.7,54.8h-24.7l-6.2-16.9
				c-3-7.8-7-11.3-16.9-11.3H70.9v55.3h12.4c9.7,0,11.3-3.2,13.4-11.5l2.4-8.9h20.1v64.5H99.1l-2.4-8.9c-2.2-7.8-3.8-11.5-13.4-11.5
				H70.9v45.4c0,10.7,4.6,14.8,14,16.1l10.5,1.6v23.1H0" data-sentry-element="path" data-sentry-source-file="FinansPartnerLogo.component.tsx" />
    </svg>;
}