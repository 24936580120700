"use client";

import clsx from "clsx";
import dynamic from "next/dynamic";
import { Fragment } from "react";
import { HtmlContent } from "@/components/HtmlContent/HtmlContent.component";
import { useViewportBreakpoint } from "@/hooks/use-viewport-breakpoint.hook";
import { compareBreakpoints } from "@/utilities/compare-breakpoints";
import type { ArticleContentModel } from "../../models/article-content-model.types";
import { Aside } from "../Aside/Aside.component";
import type { CollapserProps } from "./components/Collapser.component";
export type FactBoxProps = ArticleContentModel.ComponentProps<"BODY" | "HEADING"> & {
  readonly isFullWidth?: boolean;
};
const CollapserComponent = dynamic<CollapserProps>(async () => import( /* webpackChunkName: "collapser" */"./components/Collapser.component").then(module => module.Collapser));
export function FactBox({
  content,
  className,
  isFullWidth = false
}: FactBoxProps) {
  const {
    currentBreakpoint: breakpoint
  } = useViewportBreakpoint();
  const isXl = compareBreakpoints(breakpoint, ">=", "xl");
  const WrapperComponent = isFullWidth || !isXl ? CollapserComponent : Fragment;
  return <Aside className={clsx("overflow-hidden border-t pt-3 sm:px-3", !isFullWidth && "2xl:border-t-0 2xl:px-0", className)} isFullWidth={isFullWidth} data-sentry-element="Aside" data-sentry-component="FactBox" data-sentry-source-file="FactBox.component.tsx">
      <WrapperComponent data-sentry-element="WrapperComponent" data-sentry-source-file="FactBox.component.tsx">
        {content.map(({
        type,
        value,
        id
      }) => <Fragment key={id}>
            {type === "HEADING" ? <h1 className="mb-2 hyphens-manual break-words font-body text-sm font-theme-bold uppercase leading-snug" dangerouslySetInnerHTML={{
          __html: value
        }} /> : null}

            {type === "BODY" ? <HtmlContent className={clsx("font-body text-base *:font-body *:text-base", "prose-ol:p-0 prose-ul:p-0", "prose-li:relative prose-li:z-0 prose-li:list-none prose-li:pb-0.5 prose-li:pl-2", "prose-li:before:absolute prose-li:before:left-0 prose-li:before:top-0.875 prose-li:before:z-10 prose-li:before:h-0.875 prose-li:before:w-0.875 prose-li:before:rounded-full prose-li:before:bg-primary")} html={value} /> : null}
          </Fragment>)}
      </WrapperComponent>
    </Aside>;
}