"use client";

import clsx from "clsx";
import type { ReactNode } from "react";
import { Link } from "@/components/Link/Link.component";
import { List } from "@/components/List/List.component";
import { ListItem } from "@/components/List/ListItem.component";
import { CorporateSubscription } from "@/components/Paywall/CreateTrialDescription/_components/CorporateSubscription/CorporateSubscription.component";
import { IndividualSubscription } from "@/components/Paywall/CreateTrialDescription/_components/IndividualSubscription/IndividualSubscription.component";
import { useSite } from "@/contexts/site/site.context";
import { useUser } from "@/contexts/user/user.context";
import type { PaywallModel } from "../models/paywall.model";
type CreateTrialDescriptionProps = {
  readonly className?: string;
  readonly createTrialForm: ReactNode;
  readonly model: PaywallModel;
};
export function CreateTrialDescription({
  className,
  createTrialForm,
  model
}: CreateTrialDescriptionProps) {
  const {
    locale
  } = useSite();
  const {
    urls: {
      loginAndReturnUrl
    }
  } = useUser();
  const {
    individualSubscription,
    numberOfFreeTrialDays,
    apps,
    corporateSubscription
  } = model;
  return <article className={clsx(className, "relative border border-solid border-silver lg:mr-2.5")} data-sentry-component="CreateTrialDescription" data-sentry-source-file="CreateTrialDescription.component.tsx">
      <div className="absolute -right-1 top-2 flex select-none whitespace-nowrap border border-solid border-silver bg-white px-2 py-1 text-xs uppercase sm:top-2.5 lg:-right-2.5 lg:top-2.5">
        <span className="border-r border-solid border-silver pr-1">
          {{
          da: "Allerede abonnent?",
          de: "Bereits registriert?",
          en: "Already a subscriber?",
          no: "Allerede abonnent?",
          sv: "Redan prenumerant?"
        }[locale]}
        </span>
        <Link className="ml-1 font-theme-bold text-primary hover:text-primary-hover" href={loginAndReturnUrl} data-sentry-element="Link" data-sentry-source-file="CreateTrialDescription.component.tsx">
          {{
          da: "Log ind her",
          de: "Hier anmelden",
          en: "Log in here",
          no: "Logg inn her",
          sv: "Logga in här"
        }[locale]}
        </Link>
      </div>
      <div className="flex flex-col gap-y-2 border-b border-solid border-silver bg-whisper px-2 pb-4 pt-10 lg:px-9 lg:pb-7 lg:pt-8">
        <h1 className="border-b border-solid border-silver pb-2 font-heading text-4xl font-theme-bold lg:text-5xl">
          {{
          da: "Læs hele artiklen",
          de: "Lesen Sie den vollständigen Beitrag",
          en: "Read the whole article",
          no: "Les hele artikkelen",
          sv: "Läs hela artikeln"
        }[locale]}
        </h1>
        <p>
          {{
          da: `Få adgang i ${numberOfFreeTrialDays} dage for 0 kr. Det kræver intet kreditkort, og du vil ikke overgå til et betalt abonnement efterfølgende.`,
          de: `Erhalten Sie einen kostenfreien Zugang für ${numberOfFreeTrialDays} Tage. Es wird keine Kreditkarte benötigt und Ihr Probezugang endet automatisch nach Ablauf des Probezeitraums.`,
          en: `Get access for ${numberOfFreeTrialDays} days for free. No credit card is needed, and you will not be automatically signed up for a paid subscription after the free trial.`,
          no: `Få tilgang i ${numberOfFreeTrialDays} dager gratis. Det krever ikke kredittkort, og det vil ikke gå over til et betalt abonnement etter endt prøveperiode.`,
          sv: `Få tillgång i ${numberOfFreeTrialDays} dagar gratis. Inget kreditkort krävs, och du kommer inte automatiskt att övergå till en betald prenumeration efter provperioden.`
        }[locale]}
        </p>
        <h2 className="font-theme-bold">
          {{
          da: "Med din prøveperiode får du:",
          de: "Mit Ihrem Probeabonnement erhalten Sie:",
          en: "With your free trial you get:",
          no: "Dette er inkludert i ditt prøveabonnement:",
          sv: "Med din provperiod får du:"
        }[locale]}
        </h2>
        <List className="mb-2 space-y-1" type="checkmark" data-sentry-element="List" data-sentry-source-file="CreateTrialDescription.component.tsx">
          <ListItem data-sentry-element="ListItem" data-sentry-source-file="CreateTrialDescription.component.tsx">
            {{
            da: "Adgang til alle låste artikler",
            de: "Zugang zu allen Inhalten",
            en: "Access all locked articles",
            no: "Adgang til alle låste artikler",
            sv: "Tillgång till alla låsta artiklar"
          }[locale]}
          </ListItem>
          <ListItem data-sentry-element="ListItem" data-sentry-source-file="CreateTrialDescription.component.tsx">
            {{
            da: "Modtag vores daglige nyhedsbreve",
            de: "Erhalten Sie unsere täglichen Newsletter",
            en: "Receive our daily newsletters",
            no: "Motta våre daglige nyhetsbrev",
            sv: "Få våra dagliga nyhetsbrev"
          }[locale]}
          </ListItem>
          {apps.appStoreUrl !== null && apps.googlePlayUrl !== null ? <ListItem>
              {{
            da: "Fuld adgang til vores app",
            de: "Zugang zu unserer App",
            en: "Access our app",
            no: "Full tilgang i vår app",
            sv: "Full tillgång i vår app"
          }[locale]}
            </ListItem> : null}
        </List>

        {createTrialForm}
      </div>
      <div className="divide-y divide-solid divide-silver bg-white px-2 lg:px-9 lg:py-4">
        {individualSubscription !== null ? <IndividualSubscription model={individualSubscription} /> : null}

        {corporateSubscription !== null ? <CorporateSubscription model={corporateSubscription} /> : null}
      </div>
    </article>;
}