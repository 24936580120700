import { clsx } from "clsx";
import { SubmitButton, type SubmitButtonProps } from "@/components/Button/components/SubmitButton/SubmitButton.component";
import { Translation } from "@/components/Translation/Translation.component";
export function CreateProfileSubmitButton(props: SubmitButtonProps) {
  const {
    className,
    ...rest
  } = props;
  /* eslint-disable react/jsx-props-no-spreading -- to be able to pass the submit button to the form, we need to spread props */
  return <SubmitButton {...rest} className={clsx(className, "lg:self-start")} data-sentry-element="SubmitButton" data-sentry-component="CreateProfileSubmitButton" data-sentry-source-file="CreateProfileSubmitButton.component.tsx">
      <Translation da="Opret profil" de="Nutzer erstellen" en="Create profile" no="Opprett profil" sv="Skapa profil" data-sentry-element="Translation" data-sentry-source-file="CreateProfileSubmitButton.component.tsx" />
    </SubmitButton>;
  /* eslint-enable */
}